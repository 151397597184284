import Plugin from 'lib/modulor/plugin'

$.fn.random = -> @eq Math.floor(Math.random() * @length)

export class AlphabetModule__Item extends Plugin
  @defaults =
    debug: false
    flipped_class_name: 'alphabet_module__item__flipped'
    current_front_class_name: 'alphabet_module__card__front__current'
    current_back_class_name: 'alphabet_module__card__back__current'

  @selector: '.alphabet_module__item'

  on_init: ->
    @$element.on "click.#{@options.name}", (e) => @flip()
    @set_indexes()

  is_flipped: -> @$element.hasClass(@options.flipped_class_name)

  get_cards: -> @$element.find('.alphabet_module__card')
  get_back_cards: -> @$element.find('.alphabet_module__card__back')
  get_front_cards: -> @$element.find('.alphabet_module__card__front')
  get_random_back_card: -> @get_back_cards().not(".#{@options.current_back_class_name}").random()
  get_random_front_card: -> @get_front_cards().not(".#{@options.current_front_class_name}").random()

  flip: ->
    setTimeout (=> @update_current_card()), (666 * 0.6)
    @$element.toggleClass(@options.flipped_class_name)

  set_indexes: ->
    @get_cards().removeClass(@options.current_front_class_name).removeClass(@options.current_back_class_name)
    @get_random_back_card().addClass(@options.current_back_class_name)
    @get_random_front_card().addClass(@options.current_front_class_name)

  update_current_card: ->
    if @is_flipped()
      $random_front_card = @get_random_front_card()
      @get_front_cards().removeClass(@options.current_front_class_name)
      $random_front_card.addClass(@options.current_front_class_name)
    else
      $random_back_card = @get_random_back_card()
      @get_back_cards().removeClass(@options.current_back_class_name)
      $random_back_card.addClass(@options.current_back_class_name)

AlphabetModule__Item.register()
