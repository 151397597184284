import Plugin from 'lib/modulor/plugin'
import ScrollIntoView from 'lib/modulor/scroll_into_view'

export class ExpandableTextModule extends Plugin
  @defaults =
    debug: false
    class_names:
      is_expanded: 'expandable_text_module__is_expanded'

  @selector: '.expandable_text_module'

  on_init: ->
    @$element.on "click.#{@options.name}", '.expandable_text_module__more a', (e) =>
      e.preventDefault()
      @toggle_body()

    @$element.on "click.#{@options.name}", '.expandable_text_module__less a', (e) =>
      e.preventDefault()
      @toggle_body()

  toggle_body: ->
    @$element.toggleClass(@options.class_names.is_expanded)
    ScrollIntoView.call(@$element)

ExpandableTextModule.register()
