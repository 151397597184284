import _debounce from 'lodash/debounce'
import Plugin from 'lib/modulor/plugin'
import textFit from 'textfit'

export class OverlayModule extends Plugin
  @defaults =
    debug: false
    debounce: 10

  @selector: '.overlay_module'

  on_init: ->
    @$element.on "click.#{@options.name}", @get_close_button().selector, (e) => @hide_overlay()
    @recalc_font_size()

  on_load: -> @recalc_font_size()
  on_ready: -> @recalc_font_size()
  on_resize: -> @recalc_font_size()
  on_turbolinks_render: -> @recalc_font_size()
  on_keyup: (e) -> @hide_overlay() if e.keyCode == 27

  get_close_button: -> @$element.find '.overlay_module__button_to_close'
  get_message: -> @$element.find '.overlay_module__message'
  get_set_session_path: -> @$element.data('set-session-path')

  recalc_font_size: -> textFit(@get_message()[0], { minFontSize: 0, multiLine: true })
  on_key_press: (keyCode) ->

  hide_overlay: ->
    @set_session()
    @on_destroy()
    @$element.remove()

  set_session: ->
    return unless url = @get_set_session_path()
    $.post
      dataType: 'js'
      url: url
      data: { _method: 'PUT' }

OverlayModule.register()
