# import { default as WebModule } from 'models/modulor/web_module/web_module'
#
# export class Content extends WebModule
#   @defaults =
#     debug: false
#     name: 'GroupPortraitModule__Form'
#
#   @selector: ".group_portrait_module__form"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# Form.register()
