import FontFaceObserver from 'fontfaceobserver'

callback = -> document.documentElement.dispatchEvent(new CustomEvent('font-load'))
handle_failure = -> null # optionally handle font loading failure

krabbesholm = new FontFaceObserver('Krabbesholm');
krabbesholm_mono = new FontFaceObserver('Krabbesholm Mono');
krabbesholm_fall = new FontFaceObserver('Krabbesholm Fall');
krabbesholm_spring = new FontFaceObserver('Krabbesholm Spring');
krabbesholm_outline = new FontFaceObserver('Krabbesholm Outline');
krabbesholm_serif = new FontFaceObserver('Krabbesholm Serif');

Promise.all([
  krabbesholm.load(),
  krabbesholm_mono.load(),
  krabbesholm_fall.load(),
  krabbesholm_spring.load(),
  krabbesholm_outline.load(),
  krabbesholm_serif.load(),
]).then callback, handle_failure
