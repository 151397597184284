import Plugin from 'lib/modulor/plugin'

export class TermToggle extends Plugin
  @defaults =
    debug: false
    term_classes:
      spring: 'term__spring'
      school_break: 'term__school_break'
      fall: 'term__fall'

  @selector: '.term_toggle'

  on_init: ->
    @$element.on 'click.TermToggle', 'input[type="radio"]', (e) =>
      @update_term()

  on_destroy: ->
    @$element.off '.TermToggle'

  get_current_term: -> @$element.find('input[type="radio"]:checked').val()

  update_term: ->
    target_class = @options.term_classes[@get_current_term()]
    for name, value of @options.term_classes
      $('body').removeClass(value)
    $('body').addClass(target_class)

TermToggle.register()
