import Plugin from 'lib/modulor/plugin'

import _throttle from 'lodash/throttle'

export class PageListModule__LinkToPreviousTerm extends Plugin
  @defaults =
    debug: false
    tolerance: '50%'

  @selector: '.page_list_module__link_to_previous_term'

  on_init: ->
    @request = undefined
    { default: @inView } = await `import('in-view' /* webpackChunkName: "in-view" */)`

    @$element.one "click.#{@options.name}", 'a', (e) =>
      e.preventDefault()
      e.stopImmediatePropagation()
      @load_more()

    @is_in_view_scroll_handler = _throttle(@handle_is_in_view.bind(this), 100)
    $(window).on "scroll.#{@options.name}", @is_in_view_scroll_handler

    if @inView.is(@element, tolerance: @options.tolerance)
      @load_more()

  on_destroy: ->
    $(window).off 'scroll', @is_in_view_scroll_handler if !!@is_in_view_scroll_handler

  get_a: -> @$element.find('a')
  get_href: -> @get_a().attr('href')
  get_label: -> @$element.find('.page_list_module__link_to_previous_term__label')
  get_loading: -> @$element.find('.page_list_module__link_to_previous_term__loading')
  get_term: -> @$element.closest('.page_list_module__term')

  handle_is_in_view: ->
    if @inView.is(@element, tolerance: @options.tolerance)
      @load_more()

  load_more: ->
    return if @request

    url = @get_href()
    $current_term = @get_term()

    @get_label().remove()
    @get_loading().show()

    @request = $.ajax
      headers: { 'page-list-module-only': true }
      url: url,
      type: 'GET'
      success: (response) =>
        $previous_term = $(response).find('.page_list_module__term')
        $previous_term_id = $previous_term.data('term-id')
        unless $(".page_list_module__term[data-term-id='#{$previous_term_id}']").length
          $current_term.after($previous_term)
        @$element.remove()

PageListModule__LinkToPreviousTerm.register()
