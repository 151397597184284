import Plugin from 'lib/modulor/plugin'

export class Gallery__Dots extends Plugin
  @defaults =
    debug: false
    dot_selector: '.gallery__dot'
    selected_dot_class_name: 'gallery__dot__selected'

  @selector: '.gallery__dots'

  on_init: ->
    @$element.on "click.#{@options.name}", @options.dot_selector, (e) =>
      attachment_image_id = $(e.currentTarget).data('attachment-slide-id')
      $('.gallery').trigger
        type: 'slide_slide'
        attachment_image_id: attachment_image_id

    @$element.on "slide_slide.#{@options.name}", (e) =>
      return unless @has_ids_for_gallery_with_ids(e.ids)
      @set_current_dot(e.id)

    if @get_dots().length == 1
      @set_current_dot(@get_ids()[0])

  get_dot: (id) -> @get_dots().filter("[data-attachment-slide-id='#{id}']")
  get_dots: -> @$element.find(@options.dot_selector)
  get_ids: -> @get_dots().map(-> $(this).data('attachment-slide-id')).toArray()

  has_ids_for_gallery_with_ids: (ids) -> @get_ids()[0] in ids

  set_current_dot: (id) ->
    @get_dots().removeClass(@options.selected_dot_class_name)
    @get_dot(id).addClass(@options.selected_dot_class_name)

Gallery__Dots.register()
