import Plugin from 'lib/modulor/plugin'

export class ExhibitionHeaderModule__Until extends Plugin
  @defaults =
    debug: false
    is_current_class_name: 'exhibition_header_module__until__is_current'

  @selector: '.exhibition_header_module__until'

  on_init: ->
    if @is_current()
      @$element.addClass(@options.is_current_class_name)

  on_destroy: ->
    @$element.removeClass(@options.is_current_class_name)

  get_dstart: -> new Date(@$element.data('dstart'))
  get_dend: -> new Date(@$element.data('dend'))
  get_today: -> new Date()

  is_current: -> @get_dstart() <= @get_today() <= @get_dend()

ExhibitionHeaderModule__Until.register()
