import Plugin from 'lib/modulor/plugin'
import ScrollIntoView from 'lib/modulor/scroll_into_view'

export class Gallery extends Plugin
  @defaults =
    debug: false
    video_slide_class_name: 'gallery__video'
    selected_class_name: '.is-selected'

  @selector: '.gallery'

  on_init: ->
    return unless @get_slides().length > 1

    { default: Flickity } = await `import('plugins/flickity' /* webpackChunkName: "flickity" */)`

    if @is_flickity()
      @resize_flickity()
      return

    @flickity = new Flickity(@get_content()[0],
      cellAlign: 'left'
      cellSelector: '.gallery__image, .gallery__video'
      contain: true
      initialIndex: @get_initial_index()
      lazyLoad: false
      pageDots: false
      prevNextButtons: true
      setGallerySize: false
    )

    @get_content().on 'change.flickity', (e) =>
      unless @is_video_present()
        @pause_play_videos()

    @get_content().on 'select.flickity', (e) =>
      unless @is_being_removed()
        id = $(@flickity.selectedElement).find('.modulor_attachment').data('id')

        if @is_include_id_in_url()
          window.history.replaceState({}, null, window.location.pathname + '#' + id)

        $('.gallery__dots').trigger
          type: 'slide_slide'
          id: id
          ids: @get_slide_ids()

    @$element.on "slide_slide.#{@options.name}", (e) =>
      id = e.attachment_image_id
      @slide_slide(id)
      ScrollIntoView.call(@$element) if @is_own_id(id)

    @slide_slide( @get_slide_id_from_hash() || @get_first_slide_id() )

  on_load: -> @resize_flickity()
  on_ready: -> @resize_flickity()
  on_turbolinks_render: -> @resize_flickity()

  on_destroy: ->
    @flickity.destroy() if @flickity
    @$element.off '.flickity'

  # this is not particularly clean way to check, if element is part of a page that is already being removed
  is_being_removed: -> @$element.closest('body').parent().length == 0
  is_flickity: -> @flickity != undefined
  is_own_id: (id) -> @get_slide(id).length
  is_include_id_in_url: -> @$element.data('include-id-in-url') == true
  is_video_present: -> @get_content().find('.gallery__video').length == 0 ? false : true

  get_content: -> @$element.find('.gallery__content')
  get_first_slide_id: -> @get_slide_ids()[0]
  get_slide: (id) -> @get_slides().has("[data-id='#{id}']")
  get_slide_ids: -> @get_slides().map(-> $(this).find('.modulor_attachment').data('id')).toArray()
  get_video_slides: -> @get_content().find('.gallery__video')
  get_slides: -> @get_content().find('.gallery__image, .gallery__video')
  get_slide_id_from_hash: -> window.location.hash.replace('#', '')
  get_initial_index: ->
    return 0 unless @is_include_id_in_url()
    slide_id_from_hash = @get_slide_id_from_hash()
    return 0 unless !!slide_id_from_hash
    return 0 unless slide_id_from_hash in @get_slide_ids()
    @get_slide_ids().indexOf(slide_id_from_hash)

  pause_play_videos: ->
    videos = @$element.find("video")
    videos.each (index, element) =>
      parent_classlist = element.parentElement.classList.value
      if parent_classlist.includes("is-selected")
        element.play()
      else
        element.pause()


  slide_slide: (id) ->
    return unless @is_own_id(id)
    $slide = @get_slide(id)
    index = $slide.index()
    @flickity.select(index)

  resize_flickity: ->
    @flickity.resize() if @flickity

Gallery.register()
