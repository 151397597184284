import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Gdpr extends Plugin
  @defaults =
    debug: false

  @selector: '.gdpr__container'

  @property "settingsElement",
    get: -> @element.querySelector(".gdpr__settings")

  @property "settingInputElements",
    get: -> @element.querySelectorAll("input[type='checkbox']")

  @property "templateElement",
    get: -> @element.querySelector(".gdpr__template")

  @property "settingsInLocalStorage",
    get: -> JSON.parse(localStorage.getItem("GDPRsettings")) if localStorage.getItem("GDPRsettings")

  @property "settings",
    get: ->
      res = {}
      for el in @settingInputElements
        res[el.name] = if el.checked then 'granted' else 'denied'
      res

  @property "isAgree",
    get: -> @settingsInLocalStorage && Object.values(@settingsInLocalStorage).some((v) -> v)

  @property "isDisagree",
    get: -> @settingsInLocalStorage && Object.values(@settingsInLocalStorage).every((v) -> !v)

  on_init: ->
    @loadSettingsFromLocalStorage()

    if @isAgree
      @inject_google_analytics()
      @add_gtag_settings()
      @hide_consent_banner()
    else if @isDisagree
      @hide_consent_banner()
    else
      @show_consent_banner()

    @$element.on 'click.Gdpr', ".gdpr__button[data-value='accept-all']", (e) =>
      @acceptAll()

    @toggleSettingsHandler = @toggleSettings.bind(@)
    $(document).on 'click.Gdpr', ".gdpr__button[data-value='customize']", @toggleSettingsHandler

    @$element.on 'click.Gdpr', ".gdpr__button[data-value='save-preferences']", (e) =>
      @save()

    @$element.on 'click.Gdpr', ".gdpr__button[data-value='reject-all']", (e) =>
      @rejectAll()

  on_destroy: ->
    $(document).off 'click.Gdpr', @toggleSettingsHandler if !!@toggleSettingsHandler

  show_consent_banner: ->
    @element.removeAttribute("hidden")

  hide_consent_banner: ->
    @element.setAttribute("hidden", "")

  inject_google_analytics: ->
    return if document.querySelector("script[src*='googletagmanager']")
    analytics = @templateElement.content.cloneNode(true)
    document.head.appendChild(analytics)

  add_gtag_settings: ->
    gtag('consent', 'update', @settings)

  acceptAll: ->
    for el in @settingInputElements
      el.checked = true
    @save()

  save: ->
    @saveSettingsToLocalStorage()

    if @isDisagree
      @resetAllSettingsToDefault()
      @saveSettingsToLocalStorage()

    @hide_consent_banner()
    @inject_google_analytics()
    @add_gtag_settings()
    @closeSettings()

  rejectAll: ->
    @resetAllSettings()
    @saveSettingsToLocalStorage()
    @hide_consent_banner()
    @closeSettings()

  resetAllSettings: ->
    for el in @settingInputElements
      el.checked = false
    @saveSettingsToLocalStorage()

  resetAllSettingsToDefault: ->
    for el in @settingInputElements
      el.checked = (el.getAttribute("data-default") == "true")

  loadSettingsFromLocalStorage: ->
    return unless @settingsInLocalStorage
    for el in @settingInputElements
      el.checked = @settingsInLocalStorage[el.name]

  saveSettingsToLocalStorage: ->
    localStorage.setItem("GDPRsettings", JSON.stringify(@settings))

  toggleSettings: ->
    @show_consent_banner()
    @settingsElement.toggleAttribute("hidden")
    @element.querySelector(".gdpr__button[data-value='customize']").toggleAttribute("hidden")
    @element.querySelector(".gdpr__button[data-value='save-preferences']").toggleAttribute("hidden")

  closeSettings: ->
    @settingsElement.setAttribute("hidden", "")
    @element.querySelector(".gdpr__button[data-value='customize']").toggleAttribute("hidden", false)
    @element.querySelector(".gdpr__button[data-value='save-preferences']").setAttribute("hidden", "")

Gdpr.register()
