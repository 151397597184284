import Plugin from 'lib/modulor/plugin'

export class SiteHeaderModule__Announcements extends Plugin
  @defaults =
    debug: false
    debounce: 100
    slide_delay: 5000
    selected_dot_class_name: 'site_header_module__announcements__dot__selected'

  @selector: '.site_header_module__announcements'

  on_init: ->
    return unless @get_announcements().length > 1

    { default: Flickity } = await `import('plugins/flickity' /* webpackChunkName: "flickity" */)`

    if @is_flickity_init()
      @resize_flickity()
      return

    @flickity = new Flickity(@get_announcements_slider()[0],
      adaptiveHeight: false
      autoPlay: @options.slide_delay
      cellAlign: 'left'
      cellSelector: '.site_header_module__announcement'
      pageDots: false
      prevNextButtons: false
    )

    @$element.on "click.#{@options.name}", '.site_header_module__announcements__dot', (e) =>
      e.preventDefault()
      id = $(e.target).data('announcement-id')
      @select_announcement(id)

    @$element.on "select.flickity", (e) =>
      announcement_id = $(@flickity.selectedElement).data('id')
      @select_dot(announcement_id)

    @select_announcement(@get_first_announcement_id())

  on_load: -> @resize_flickity()
  on_ready: -> @resize_flickity()
  on_turbolinks_render: -> @resize_flickity()

  on_destroy: ->
    @flickity.destroy() if @flickity
    @$element.off '.flickity'

  get_announcement: (id) -> @get_announcements().filter("[data-id='#{id}']")
  get_announcements: -> @get_announcements_slider().find '.site_header_module__announcement'
  get_announcements_slider: -> @$element.find '.site_header_module__announcements__slider'
  get_announcements_dot: (announcement_id) -> @get_announcements_dots().filter "[data-announcement-id='#{announcement_id}']"
  get_announcements_dots: -> @$element.find '.site_header_module__announcements__dot'
  get_first_announcement: -> @get_announcements().first()
  get_first_announcement_id: -> @get_first_announcement().data('id')

  is_flickity_init: -> !!@flickity

  select_announcement: (id) ->
    return unless id
    $announcement = @get_announcement(id)
    index = $announcement.index()
    @flickity.select(index)
    @select_dot(id)

  select_dot: (announcement_id) ->
    @get_announcements_dots().removeClass(@options.selected_dot_class_name)
    @get_announcements_dot(announcement_id).addClass(@options.selected_dot_class_name)

  resize_flickity: ->
    @flickity.resize() if @flickity

SiteHeaderModule__Announcements.register()
